<template>
  <div>
    <label class="label">
      {{ $t('channel.channelType') }}
    </label>
    <div>
      <m-radio-cards
          v-model="value"
          :title="$t('channel.channelTypes.general')"
          :description="$t('channel.channelTypes.generalDescription')"
          :value="channelTypes.General"
          :disabled="disabled || loading"
          :recalculate-accordion="recalculateAccordion"
      />
      <m-radio-cards
          v-model="value"
          :title="$t('channel.channelTypes.event')"
          :description="$t('channel.channelTypes.eventDescription')"
          :value="channelTypes.Event"
          :disabled="disabled || loading"
          :recalculate-accordion="recalculateAccordion"
      />
      <m-radio-cards
          v-model="value"
          :title="$t('channel.channelTypes.researchConcierge')"
          :description="$t('channel.channelTypes.researchConciergeDescription')"
          :value="channelTypes.ResearchConcierge"
          :disabled="disabled || editing || loading || researchConciergeDisabled"
          :recalculate-accordion="recalculateAccordion"
          :element-title="researchConciergeDisabled ? $t('channel.channelTypes.researchConceirgeAtCapacity') : ''"
      >
        <p class="mt-3">{{ $t('dict.earlyBetaReleaseNotice') }}</p>
        <p class="mt-3 is-italic" v-if="researchConciergeDisabled">{{ $t('channel.channelTypes.researchConceirgeAtCapacity') }}</p>
      </m-radio-cards>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChannelType } from '@/api/ms-channel/services/interfaces/Channel';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import SettingService from '@/api/ms-app-settings/services/SettingService';
import config from '@/config';

@Component({
  components: { MRadioCards }
})
export default class MChannelBasicsChannelType extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ChannelType;
  @Prop({ required: false, default: false })
  recalculateAccordion!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({required: false, default: false})
  editing!: boolean;

  value: ChannelType = ChannelType.General;
  channelTypes = ChannelType;
  loading = false;
  researchConciergeDisabled = false;

  created () {
    this.value = this.activeValue;
    this.getResearchConciergeStatus();
  }

  // get state of research concierge
  async getResearchConciergeStatus () {
    // no need to run the check if it's already disabled or editing which cannot set to RC anyway
    if( !this.disabled && !this.editing ){
      this.loading = true;
      const setting = await SettingService.settingGet({
        name: config.settings.researchConcierge
      });
      this.researchConciergeDisabled = !setting.state.active;
      this.loading = false;
    }
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
</script>