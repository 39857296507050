<template>
  <div class="MUserProfileHeader">
    <a-router-link-your-profile :hasIcon="false" class="hide-on-mobile">
      <a-user-profile-pic :username="user.username"/>
    </a-router-link-your-profile>

    <b-dropdown
        ref="dropdownMenu"
        :append-to-body="false"
        :max-height="maxHeight"
        :mobile-modal="false"
        :scrollable="true"
        aria-role="menu"
        class="header-dropdown"
        position="is-bottom-left"
        @active-change="toggleMenuIcon"
    >
      <a slot="trigger"
         class="user-dropdown-trigger"
         role="button">
        <a-user-profile-pic :username="user.username" class="show-on-mobile"/>
        <span class="user-profile-username">{{ user.firstName|maxForenameLength }} </span>
        <x-icon v-if="isDropdownOpen" class="dropdown-icon" size="1.2x"></x-icon>
        <chevron-down-icon v-else class="dropdown-icon" size="1.2x"></chevron-down-icon>
      </a>

      <!-- MAIN PAGES -->
      <b-dropdown-item aria-role="menuitem" custom>
        <span class="dropdown-group-title"><b> {{ $t('dict.mainPages') }}</b></span>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link>
        <a-router-link-dashboard :has-icon="true"/>
      </b-dropdown-item>

      <b-dropdown-item
          aria-role="menuitem"
          has-link
      >
        <a-router-link-your-profile :hasIcon="true">
          {{ $t('dict.myProfile') }}
        </a-router-link-your-profile>
      </b-dropdown-item>

      <b-dropdown-item
          aria-role="menuitem"
          has-link
      >
        <a-router-link-your-channels :hasIcon="true">
          {{ $t('dict.myChannels') }}
        </a-router-link-your-channels>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link>
        <a-router-link-shopping-list :hasIcon="true"/>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link>
        <a-router-link-setting-watchers :hasIcon="true"/>
      </b-dropdown-item>

      <hr class="dropdown-divider">

      <!-- SETTINGS -->
      <b-dropdown-item aria-role="menuitem" custom>
        <span class="dropdown-group-title"><b>{{ $t('dict.settings') }}</b></span>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" class="menu-language-link" has-link>
        <a class="menu-profile-item" @click="openLanguageSelectionModal">
          <span :class="'fi-' + localeToISOA2(currentLanguage)" class="fi mr-2"></span>
          <span>
            {{ $t('dict.language') }}
          </span>
        </a>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link>
        <a-router-link-invitations :hasIcon="true"/>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link>
        <a-router-link-settings :hasIcon="true"/>
      </b-dropdown-item>

      <hr class="dropdown-divider">

      <!-- ABOUT -->
      <b-dropdown-item aria-role="menuitem" custom>
        <span class="dropdown-group-title"><b>{{ $t('dict.about') }}</b></span>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link>
        <router-link :to="{ name: routeName.ROUTE_INFO_CONTACT }" class="menu-profile-item">
          <map-pin-icon class="header-menuitem-icon" size="1x"/>
          {{ $t('footer.contact') }}
        </router-link>
      </b-dropdown-item>


      <b-dropdown-item aria-role="menuitem" has-link>
        <router-link :to="{ name: routeName.ROUTE_INFO_WHAT_IS_AUTHED }" class="menu-profile-item">
          <users-icon class="header-menuitem-icon" size="1x"/>
          {{ $t('footer.forPeople') }}
        </router-link>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link>
        <router-link :to="{ name: routeName.ROUTE_INFO_BUSINESS }" class="menu-profile-item">
          <briefcase-icon class="header-menuitem-icon" size="1x"/>
          {{ $t('footer.forBusiness') }}
        </router-link>
      </b-dropdown-item>

      <hr class="dropdown-divider">

      <!-- HELP -->
      <b-dropdown-item aria-role="menuitem" custom>
        <span class="dropdown-group-title"><b>{{ $t('dict.help') }}</b></span>
      </b-dropdown-item>
      <b-dropdown-item aria-role="menuitem" has-link id="liveChat">
        <a class="menu-profile-item" @click="openChat">
          <message-square-icon class="header-menuitem-icon" size="1x"/>
          {{ $t('header.menu.liveSupport') }}
        </a>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link id="takeTheTour">
        <a class="menu-profile-item" @click="triggerTour">
          <a-tour-svg-icon class="header-menuitem-icon" size="1"/>
          {{ $t('vueTour.initiateTour') }}
        </a>
      </b-dropdown-item>

      <b-dropdown-item aria-role="menuitem" has-link>
        <a href="https://help.liffery.com" target="_blank" class="menu-profile-item">
          <help-circle-icon class="header-menuitem-icon" size="1x"/>
          {{ $t('footer.help') }}
        </a>
      </b-dropdown-item>

      <hr class="dropdown-divider">

      <!-- LOGOUT -->
      <b-dropdown-item aria-role="menuitem" has-link>
        <a-router-link-logout :hasIcon="true" class="logout-link"/>
      </b-dropdown-item>

      <hr class="dropdown-divider">

      <b-dropdown-item aria-role="menuitem" :custom="true">
        <div class="has-text-centered font-caption" style="width: 100%">
          (App version {{ appVersion }})
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
.MUserProfileHeader {
  display: flex;
  align-items: center;
}

.user-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

::v-deep img.user-profile-image {
  max-height: 50px;
}

::v-deep .dropdown-menu {
  min-width: 15rem;
  margin-top: 38px;
}

.dropdown-group-title {
  text-transform: uppercase;
}

.dropdown-icon {
  margin-top: 3px;
  margin-left: 4px;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.user-profile-username {
  transition: color .2s;
}

.hide-on-mobile {
  display: block;
}

.show-on-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .dropdown-icon {
    margin-left: -2px;
  }

  .user-profile-username {
    display: none;
  }

  .hide-on-mobile {
    display: none;
  }

  .show-on-mobile {
    display: block;
  }
}

@media screen and (max-width: 470px) {
  ::v-deep .AUserProfilePic {
    img {
      max-width: 40px;
    }
  }
}

@media screen and (max-width: 440px) {
  ::v-deep .AUserProfilePic {
    img {
      max-width: 33px;
    }
  }
}

@media screen and (max-width: 500px) {
  ::v-deep .dropdown-menu {
    position: fixed;
    top: 68px;
    right: 0 !important;
    bottom: 0;
    left: 0 !important;
    overflow: auto;
    margin-top: 0 !important;
    border-top: 1px solid #ededed;
    background-color: #fff;

    @media screen and (max-height: 600px) {
      padding-bottom: 2rem;
    }

    &::after {
      content: unset;
    }

    .dropdown-content {
      box-shadow: none;
    }

    .dropdown-group-title {
      padding-left: 1rem;
    }

    a.menu-profile-item {
      padding: 1rem 2rem;
    }
  }

  ::v-deep .select,
  ::v-deep select {
    width: 100%;
  }
}
</style>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import ARouterLinkChannelManagement from '@/components/atoms/link/ARouterLinkChannelManagement.vue';
import ARouterLinkDashboard from '@/components/atoms/link/ARouterLinkDashboard.vue';
import ARouterLinkLogout from '@/components/atoms/link/ARouterLinkLogout.vue';
import ARouterLinkSettings from '@/components/atoms/link/ARouterLinkSettings.vue';
import ARouterLinkShoppingList from '@/components/atoms/link/ARouterLinkShoppingList.vue';
import ARouterLinkConnections from '@/components/atoms/link/ARouterLinkConnections.vue';
import ARouterLinkSecurity from '@/components/atoms/link/ARouterLinkSecurity.vue';
import ARouterLinkLanguage from '@/components/atoms/link/ARouterLinkLanguage.vue';
import {
  BriefcaseIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
  GlobeIcon,
  HelpCircleIcon,
  MapPinIcon,
  MessageSquareIcon,
  TruckIcon,
  UsersIcon,
  XIcon
} from 'vue-feather-icons';

import { AuthenticationStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import ARouterLinkEmailNotifications from '@/components/atoms/link/ARouterLinkEmailNotifications.vue';
import ARouterLinkYourProfile from '@/components/atoms/link/ARouterLinkYourProfile.vue';
import ARouterLinkInvitations from '@/components/atoms/link/ARouterLinkInvitations.vue';
import isTouchScreen from '@/utils/isTouchScreen';
import ATourSvgIcon from '@/components/atoms/icon/svg/ATourSvgIcon.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { AvailableTours } from '@/components/organisms/OVueTour.types';
import ARouterLinkYourChannels from '../atoms/link/ARouterLinkYourChannels.vue';
import truncateText from '@/utils/truncateText';
import ARouterLinkSettingWatchers from '@/components/atoms/link/ARouterLinkSettingWatchers.vue';

@Component({
  components: {
    ARouterLinkSettingWatchers,
    ARouterLinkChannelManagement,
    ARouterLinkConnections,
    ARouterLinkDashboard,
    ARouterLinkEmailNotifications,
    ARouterLinkInvitations,
    ARouterLinkLanguage,
    ARouterLinkLogout,
    ARouterLinkProfile,
    ARouterLinkSecurity,
    ARouterLinkSettings,
    ARouterLinkShoppingList,
    ARouterLinkYourChannels,
    ARouterLinkYourProfile,
    ATourSvgIcon,
    AUserProfilePic,
    MLanguageSwitcher,
    BriefcaseIcon,
    ChevronDownIcon,
    ExternalLinkIcon,
    GlobeIcon,
    HelpCircleIcon,
    MapPinIcon,
    MessageSquareIcon,
    UsersIcon,
    TruckIcon,
    XIcon
  },
  filters: {
    maxForenameLength: (name) => truncateText(name, 25)
  }
})
export default class MUserProfileHeader extends Vue {
  isDropdownOpen: boolean = false;
  name = RouteNames.ROUTE_PROFILE;

  eventCallerId = 'TProfile';

  dropdownMenu!: HTMLElement | null;
  routeName = RouteNames;
  maxHeight = 0;

  $refs!: {
    dropdownMenu: any
  };

  get currentLanguage () {
    return this.$i18n.locale;
  }

  get appVersion () {
    return window.appVersion;
  }

  get user () {
    return AuthenticationStore.user;
  }

  created () {
    this.bindEvents();
  }

  mounted () {
    this.dropdownMenu = document.querySelector('.MUserProfileHeader .dropdown');
    this.setMaxSize();
    this.toggleMenuIcon();
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  unbindEvents () {
    const { eventCallerId } = this;
    window.removeEventListener('resize', this.setMaxSize);
    EventBus.$remove(EventBusEvents.TOUR_SHOW_PROFILE_HEADER, eventCallerId);
    EventBus.$remove(EventBusEvents.TOUR_HIDE_PROFILE_HEADER, eventCallerId);
  }

  bindEvents () {
    const { eventCallerId } = this;
    window.addEventListener('resize', this.setMaxSize);
    EventBus.$on(EventBusEvents.TOUR_SHOW_PROFILE_HEADER, eventCallerId, this.showMenu);
    EventBus.$on(EventBusEvents.TOUR_HIDE_PROFILE_HEADER, eventCallerId, this.hideMenu);
  }

  setMaxSize () {
    const navBar = document.querySelector('.TMainLayout .MHeader');
    if (navBar) {
      // we're in the app
      this.maxHeight = window.innerHeight - navBar.getBoundingClientRect().height - 15;
    } else {
      // we're on a public landing page
      const navBarTop = document.querySelector('.header-top');
      const navBarMain = document.querySelector('.header-main');
      if (navBarTop && navBarMain) {
        this.maxHeight = window.innerHeight - (
          navBarTop.getBoundingClientRect().height + navBarMain.getBoundingClientRect().height
        ) - 15;
      }
    }
  }

  localeToISOA2 (locale: string): string {
    return locale.slice(-2).toLowerCase();
  }

  toggleMenuIcon (active?: boolean) {
    this.$nextTick(() => {
      this.isDropdownOpen = !!this.dropdownMenu?.classList.contains('is-active');
    });
    if (isTouchScreen()) {
      document.documentElement.className = active ? 'is-clipped' : '';
    }
  }

  showMenu () {
    //if it's not open, ensure it is
    if (!this.dropdownMenu?.classList.contains('is-active')) {
      this.$refs.dropdownMenu.toggle();
    }
  }

  hideMenu () {
    //if it is open, ensure it is closed
    if (this.dropdownMenu?.classList.contains('is-active')) {
      this.$refs.dropdownMenu.toggle();
    }
  }

  triggerTour () {
    EventBus.$emit(EventBusEvents.TOUR_START, AvailableTours.SelectATour);
  }

  openLanguageSelectionModal () {
    EventBus.$emit(EventBusEvents.SELECT_LANGUAGE);
  }

  openChat (): void {
    window.HelpCrunch('openChat');
  }
}
</script>
