<template>
  <div :class="{[routename]: routename}">
    <footer class="footer content" :class="{[routename]: routename, 'is-justify-content-center': isLandingFunnelPage}">

      <p v-if="isLandingFunnelPage">
        <img src="@/assets/landing-pages/new/logo.svg" alt="Liffery Logo"/>
        <img src="@/assets/landing-pages/new/liffery.svg" alt="Liffery Logo" class="ml-2"/>
      </p>

      <template v-else>
        <div class="footer-logo">
          <router-link :to="{name: routeNames.ROUTE_INFO_WHAT_IS}">
            <img src="@/assets/landing-pages/new/logo.svg" alt="Liffery Logo"/>
            <img src="@/assets/landing-pages/new/liffery.svg" alt="Liffery Logo" class="ml-2"/>
          </router-link>
        </div>

        <div class="footer-apps is-relative">
          <div class="is-flex is-flex-direction-column">
            <a-link link="https://play.google.com/store/apps/details?id=com.liffery.www" class="pb-1" v-if="!isIosApp">
              <img src="@/assets/landing-pages/forPeople/2023/Liffery-android-app.svg" alt="Liffery Logo"/>
            </a-link>

            <a-link link="https://apps.apple.com/de/app/liffery/id1577076705?l=en">
              <img src="@/assets/landing-pages/forPeople/2023/Liffery-ios-app.svg" alt="Liffery Logo"/>
            </a-link>
          </div>

          <div class="is-flex is-flex-direction-column">
            <a-link link="https://chrome.google.com/webstore/detail/liffery/mhfpnmeaefedihplobamociejcdmifcp"
                    class="pb-1">
              <img src="@/assets/landing-pages/forPeople/2023/Liffery-chrome-ext.svg" alt="Liffery Logo"/>
            </a-link>

            <a @click="loginPrompt">
              <img src="@/assets/landing-pages/forPeople/2023/Liffery-web-browser-app.svg" alt="Liffery Logo"/>
            </a>

          </div>
        </div>

        <div class="footer-links">
          <div class="left">
            <router-link :to="{ name: routeNames.ROUTE_INFO_BUSINESS }">
              Liffery for Business
            </router-link>
            <router-link :to="{ name: routeNames.ROUTE_INFO_HELP }">
              Help & Legal
            </router-link>
          </div>
          <div class="right">
            <router-link :to="{ name: routeNames.ROUTE_INFO_CAREERS }">
              Careers
            </router-link>
            <router-link :to="{ name: routeNames.ROUTE_INFO_CONTACT }">
              Contact Us
            </router-link>
          </div>
        </div>
      </template>

    </footer>
    <p class="copy-write has-text-centered font-body-s mt-4">
      © {{ year }} Liffery Ltd. All rights reserved. Registered in England No. 13241757.
    </p>
  </div>
  <!-- Footer -->
</template>

<style scoped lang="scss">
$navbar-padding-x: 6.875rem;


.infoWhatIs, .infoAboutUs {
  background-color: $landingPageCream;
}

.copy-write {
  text-align: center;
  padding-bottom: 4rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-right: $navbar-padding-x;
  padding-left: $navbar-padding-x;
  padding-top: 6rem;
  padding-bottom: 2rem;

  &.infoWhatIs,
  &.infoAboutUs {
    background-color: $landingPageCream;
  }


  @media screen and (max-width: $widescreen) {
    padding-right: 3.4rem;
    padding-left: 3.4rem;
  }

  @media screen and (max-width: $desktop) {
    padding-right: 2rem;
    padding-left: 2rem;

    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    gap: 3rem;
  }

  @media screen and (max-width: $mobile) {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .footer-logo {
    @media screen and (max-width: $desktop) {
      display: flex;
      justify-content: center;
      flex-direction: column;

      a {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
      }
    }
  }

  .footer-apps {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-end;
    justify-items: center;

    a {
      &:hover {
        filter: drop-shadow(0px 3.27px 15px rgba(0, 0, 0, 0.25));
      }

      &:active {
        filter: unset;
      }
    }
  }

  .footer-links {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8rem;

    @media screen and (max-width: 1550px) {
      gap: 4rem;
    }

    @media screen and (max-width: 1120px) {
      gap: 2rem;
    }

    @media screen and (max-width: $mobile) {
      .left {
        align-items: flex-start;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1.5rem;

      @media screen and (max-width: 1120px) {
        gap: 1rem;
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ALink from '@/components/atoms/link/ALink.vue';
import { RouteNames } from '@/router/RouteNames';
import isIosApp from '@/utils/isIosApp';
import { AuthenticationStore } from '@/store';
import { EnumRegisteredFromAction } from '@/store/modules/interfaces/AuthenticationModule';

@Component({
  components: { ALink }
})
export default class MFooterSeo extends Vue {

  routeNames = RouteNames;

  isIosApp = isIosApp();

  get isLandingFunnelPage () {
    return this.$route.meta && this.$route.meta.landingFunnelPage === true;
  }

  get year () {
    return new Date().getFullYear();
  }

  get routename () {
    return this.$route.name;
  }

  loginPrompt () {
    AuthenticationStore.TOGGLE_PROMPT_SIGNUP({
      state: true,
      metaData: {
        registeredFromAction: EnumRegisteredFromAction.ctaFooterSeo
      }
    });
  }
}
</script>
