<template>
  <div :class="{ 'sidebar-open': open }" class="OChatSidebar">
    <div class="chat-wrapper">
      <m-chat-toggle-handle
          :force-show="forceShowChatToggleLight"
          :is-open="open"
          :unread-count="chatUnreadCount"
          @click="toggleSidebar"
      />
      <m-chat-box
          :channel-slug="channelSlug"
          :chat-enabled="chatEnabled"
          :first-unread-message="chatUnread && chatUnread.chatId"
          :has-no-more-messages="hasNoMoreMessages"
          :loading="loadingMessages"
          :messages="messages"
          :unread-count="chatUnreadCount"
          :socket-connection-failed="socketConnectionFailed"
          v-on:send-message="postMessage"
          v-on:chatbox-scrolled-to-top="loadMore"
          v-on:chatbox-scrolled-not-bottom="atBottomContainer = false"
          v-on:chatbox-scrolled-at-bottom="chatScrolledToBottom"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'OChatSidebar';
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChannelService from '@/api/ms-chat/services/ChannelService';

import MChatToggleHandle from '@/components/molecules/MChatToggleHandle.vue';
import MChatBox from '@/components/molecules/MChatBox.vue';
import { Chat } from '@/api/ms-chat/services/interfaces';
import { Datum as ChatsDatum } from '@/api/ms-chat/services/interfaces/Chats';
import { Type } from '@/api/ms-chat/services/interfaces/Chat';
import WebSocket, { RoomType } from '@/socket/WebSocket';
import EventBus, { EventBusEvents } from '@/EventBus';
import { AuthenticationStore, LifferyTourStore } from '@/store';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { ChannelMemberLastUnreadChat } from '@/api/ms-channel/services/interfaces';
import { ToursSeen } from '@/api/ms-authentication/services/interfaces/UserSettingsPut';

@Component({
  components: { MChatBox, MChatToggleHandle }
})
export default class OChatSidebar extends Vue {
  @Prop({ required: true })
  channelId!: string;

  @Prop({ required: true })
  channelSlug!: string;

  @Prop({ default: true })
  chatEnabled!: boolean;

  open: boolean = false;
  loadingMeta: boolean = false;
  loadingMessages: boolean = false;
  chatUnread: ChannelMemberLastUnreadChat | null = null;
  chatUnreadCount: number = 0;
  messages: ChatsDatum[] = [];
  offset: number = 0;
  lastFetchFoundCount: number = -1;
  atBottomContainer: boolean = false;
  forceShowChatToggleLight: boolean = false;
  hasNoMoreMessages: boolean = false;
  socketConnectionFailed = false;

  // The timeout will be closed by any action on the open/close toggle
  bounceCloseTimeout: any;

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  get toursSeen (): ToursSeen {
    return LifferyTourStore.getToursSeen;
  }

  async created () {
    this.loadingMeta = true;
    this.listenerForNewMessages();

    await this.fetchChatMessages();
    await this.unreadFetch();
    await this.calculateUnreadCount();
    try{
      await this.joinChannelChatSocketRoom();
      this.socketConnectionFailed = false;
    } catch(e) {
      this.socketConnectionFailed = true;
    }
    if (this.$route.query?.chat === 'open') {
      this.open = true;
    }
    this.bounceTheSidebar();
    this.loadingMeta = false;
  }

  bounceTheSidebar () {
    if (this.toursSeen.channel && this.toursSeen.channel.chatOpened < 2) {
      const sidebar = document.querySelector('.OChatSidebar')!;
      sidebar.classList.add('sidebar-half-open');
      LifferyTourStore.CHANNEL_CHAT_OPENED(1);
      this.bounceCloseTimeout = setTimeout(() => this.bounceClose(), 5000);
    }
  }

  bounceClose () {
    const sidebar = document.querySelector('.OChatSidebar')!;
    sidebar.classList.add('sidebar-close');
    sidebar.classList.remove('sidebar-half-open');
  }

  async unreadFetch (): Promise<void> {
    this.chatUnread = await ChannelMemberService.channelMemberSlugChatUnreadGet({
      slug: this.channelSlug
    });
  }

  async calculateUnreadCount (): Promise<void> {
    if (!this.chatUnread || this.chatUnread.chatId === '') {
      return;
    }

    const chatUnreadCount = this.messages.findIndex((msg: Chat) => {
      return msg._id === this.chatUnread?.chatId;
    });
    if (chatUnreadCount !== -1) {
      this.chatUnreadCount = this.messages.length - chatUnreadCount;
    } else {
      this.forceShowChatToggleLight = true;
    }
  }

  async joinChannelChatSocketRoom (): Promise<void> {
    await WebSocket.joinRoom(RoomType.ChannelChat, this.channelId);
  }

  beforeDestroy () {
    WebSocket.leaveRoom(RoomType.ChannelChat, this.channelId);
    EventBus.$remove(EventBusEvents.CHAT_NEW_MSG, 'OChatSidebar');
  }

  listenerForNewMessages (): void {
    EventBus.$on(EventBusEvents.CHAT_NEW_MSG, 'OChatSidebar', (payload: Chat) => {
      if (payload.author.username !== this.currentUser.username) {
        // double check message is for this room and the message is not already in the stack
        this.messageAddOne(payload);

        // if the bar is closed, turn the light on by fetching the count
        if (!this.open) {
          this.unreadFetch().then(() => {
            this.calculateUnreadCount();
          });
        } else {
          // sidebar is open
          const chatBox = document.querySelector('.chat-messages-wrapper')!;

          // don't show light if container is scrolled to the bottom
          this.forceShowChatToggleLight = Math.abs(chatBox.scrollTop) > 0;
          this.markRead();
        }
      }
    });
  }

  async fetchChatMessages (): Promise<void> {
    this.loadingMessages = true;

    const { data = [] } = await ChannelService.channelChannelIdGet(
      { channelId: this.channelId },
      { offset: this.offset }
    );

    this.hasNoMoreMessages = data.length < 20;

    const newDataArr = data.filter((datum: Chat) => {
      const existingIndex = this.messages.findIndex((msg: Chat) => datum._id === msg._id);
      return existingIndex === -1;
    });
    this.messages = newDataArr.concat(this.messages);
    this.lastFetchFoundCount = data.length;
    this.loadingMessages = false;
  }

  toggleSidebar (): void {
    clearTimeout(this.bounceCloseTimeout);
    this.open = !this.open;
    this.markRead();
    this.turnLightOff();

    if (this.toursSeen.channel && this.toursSeen.channel.chatOpened < 2) {
      LifferyTourStore.CHANNEL_CHAT_OPENED(3);
    }

    if (!this.open) {
      this.clearChatUnread();
    }
  }

  async markRead (): Promise<void> {
    if (this.loadingMessages) {
      setTimeout(() => {
        this.markRead();
      }, 500);
    }
    await ChannelMemberService.channelMemberSlugChatReadPatch({ slug: this.channelSlug });
  }

  turnLightOff (): void {
    setTimeout(() => {
      this.forceShowChatToggleLight = false;
      this.chatUnreadCount = 0;
    }, 2000);
  }

  clearChatUnread (): void {
    if (this.chatUnread) {
      this.chatUnread!.chatId = '';
    }
  }

  chatScrolledToBottom (): void {
    this.atBottomContainer = true;
    this.turnLightOff();
  }

  async loadMore (): Promise<void> {
    // the API returns batches of 20, if it returned with less that 20, no need to try and fetch more
    if (this.lastFetchFoundCount !== -1 && this.lastFetchFoundCount === 20 && !this.loadingMessages) {
      this.offset = this.messages.length;
      await this.fetchChatMessages();
    }
  }

  postMessage (message: string): void {
    const timeout = setTimeout(() => {
      this.loadingMessages = true;
    }, 1000);

    ChannelService.channelChannelIdPost(
      { msg: message, type: Type.Channel },
      { channelId: this.channelId },
      { channelSlug: this.channelSlug }
    ).then((chat: Chat) => {
      clearTimeout(timeout);
      this.loadingMessages = false;

      this.messageAddOne(chat);
      this.turnLightOff();
      this.clearChatUnread();
    });
  }

  messageAddOne (chat: Chat): void {
    const existingIndex = this.messages.findIndex((msg: Chat) => msg._id === chat._id);
    if (chat.type === Type.Channel && chat.relationId === this.channelId && existingIndex === -1) {
      this.messages.push(chat);
    }
  }
}
</script>
