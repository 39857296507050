export enum CookieNames {
  PRIVACY_POLICY_AGREED= 'privacy-policy-agreed',
  TERMS_OF_USE_AGREED = 'terms-of-use-agreed',
  LANGUAGE_SWITCHER = 'language-switcher',
  APPROVED_PRERELEASE = 'approved_prerelease',
  JWT_RENEWAL = 'rt',
  JWT_ACCESS = 'at',
  IS_SSR = 'ssr',
  FIRST_EVER_PAGE_LOADED = '1epl',
}
